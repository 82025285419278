<template>
  <div class="profile-page">
    <div class="top-right-overflow-menu ma-1">
      <v-menu v-if="appBarActions.length" right bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="action in appBarActions"
            :key="action.id"
            @click="action.onClick"
          >
            <v-icon class="pr-2" size="22" v-if="action.icon">{{
              action.icon
            }}</v-icon>
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <!-- current app version -->
          <p class="ma-0 text-caption mt-2 mx-2 font-weight-medium text-center">
            v{{ endpoints.currentVersion }}
          </p>
        </v-list>
      </v-menu>
    </div>
    <div
      class="background-rectangle"
      :style="{
        background: $vuetify.theme.themes.light.primary,
        height: '150px',
      }"
    >
      <div class="pa-3">
        <h3 class="align-start white--text">
          Hi {{ currentUser.full_name.split(" ")[0] }}!
        </h3>
        <h3 class="align-start white--text">How are you doing today?</h3>
      </div>
      <div class="profile-image-container">
        <v-avatar v-if="currentUser.image" size="130px" class="mb-2">
          <v-img :src="currentUser.image" class="profile-image"></v-img>
        </v-avatar>
      </div>
    </div>
    <div class="profile-details">
      <v-row justify="center">
        <v-col md="5" align="center">
          <h2>{{ currentUser.full_name }}</h2>
          <p class="mb-2">
            {{ currentUserDesignation }} - {{ currentUser.custom_institution_name || currentInstitution.name }}
          </p>
          <p>
            <v-icon>mdi-account-circle</v-icon> {{ currentUser.username }} |
            <v-icon>mdi-phone</v-icon> {{ currentUser.phone }}
          </p>
          <v-row
            v-if="
              currentUser.dob &&
              currentInstitution.preferences.app_student_extra_data
            "
            justify="center"
            align="center"
            class="mb-3"
          >
            <v-icon class="mx-2">mdi-cake</v-icon>
            {{ moment(currentUser.dob).format("D MMMM YYYY") }}
          </v-row>

          <v-row
            v-if="
              currentUser.address &&
              currentInstitution.preferences.app_student_extra_data
            "
            justify="center"
            align="center"
            class="mb-3 px-3"
          >
            <p>
              <v-icon class="mx-1">mdi-home</v-icon> {{ currentUser.address }}
            </p>
          </v-row>

          <!-- Birthday -->

          <!-- <v-list-item>
          <v-list-item-content>
              <v-list-item-title> Birthday </v-list-item-title>
              <v-list-item-subtitle> 23/3/2000 </v-list-item-subtitle>
          </v-list-item-content>

            <v-list-item-icon class="align-self-center mr-n3">
                <intract-drop-down-menu 
                  v-if="(questionItem.user.id == currentUser.id) || currentUser.is_faculty || currentUser.is_admin"
                  :options-list="getDropdownOptions(questionItem)"
                />
                <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>

        </v-list-item>  -->

          <v-card
            class="text-left mb-2 mx-2 post-card py-1"
            v-if="!currentUser.dob && currentUser.is_student"
          >
            <v-list-item>
              <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">
                  Birthday
                </div>
                <div class="red--text body-2">Not set</div>
              </v-list-item-content>

              <v-list-item-icon class="align-self-center">
                <v-btn class="primary" @click="visible = true"> Set </v-btn>

                <!-- <v-icon>mdi-pencil</v-icon> -->
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <v-expansion-panels
            class="px-2 mb-3"
            v-if="userSubjects && userSubjects.length"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Subjects ({{ userSubjects.length }})</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list class="text-left pa-0">
                  <div v-for="subject in userSubjects" :key="subject.id">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          subject.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="subject.room">{{
                            subject.sections
                          }}</span>
                          <span v-else>
                            <span
                              v-for="room in subject.eligible_rooms"
                              :key="room.id"
                              >{{ room.sections }}
                              <span
                                v-if="
                                  subject.eligible_rooms.length > 1 &&
                                  subject.eligible_rooms.indexOf(room) !=
                                    subject.eligible_rooms.length - 1
                                "
                                >,
                              </span>
                            </span>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon v-if="!subject.room">
                        <v-chip small> Extra </v-chip>
                      </v-list-item-icon>
                    </v-list-item>
                  </div>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Extra Subjects -->
          <!-- <v-expansion-panels
            class="px-2 mb-3"
            v-if="extraSubjects && extraSubjects.length"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Extra Subjects ({{
                  extraSubjects.length
                }})</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list class="text-left pa-0">
                  <div v-for="subject in extraSubjects" :key="subject.id">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          subject.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->

          <change-password-sheet
            :visible="showChangePasswordSheet"
            @close="showChangePasswordSheet = false"
          />
        </v-col>
      </v-row>
    </div>

    <!-- <intract-bottom-sheet
      title="Set Birthday"
      :visible="visible"
      @close="visible=false"
    > 
      <v-menu>
        <v-text-field>

        </v-text-field>
      </v-menu>

    </intract-bottom-sheet> -->

    <v-dialog v-model="visible" persistent max-width="500px">
      <v-date-picker 
      v-model="userCopy.dob"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      full-width
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="userCopy.dob=currentUser.dob;visible=false"> Cancel </v-btn>
        <v-btn color="primary" text @click="setBirthday" :disabled="!userCopy.dob"> Ok </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-model="userDetails.visible" persistent max-width="500px">
      <v-card color="primary white--text py-2 px-1">
        <v-card-title
          >Edit your profile details
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="white"
            class="ml-2"
            @click="userDetails.visible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>

      <v-card>
        <v-card-text class="px-4">
          <v-form>
            <v-list-item class="pt-3 pb-1">
              <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">
                  Edit your profile picture
                </div>

                <template>
                  <v-col class="text-center">
                    <div>
                      <v-avatar size="100px" class="mb-2">
                        <v-img v-if="userCopy.image" :src="userCopy.image"></v-img>
                      </v-avatar>
                    </div>

                    <v-btn
                      class="my-2"
                      color="primary"
                      @click="$refs.profileImageField.$refs.input.click()"
                      ><v-icon class="mr-3">mdi-image</v-icon
                      ><span>Upload Image</span></v-btn
                    >

                    <v-file-input
                      v-show="false"
                      class="ma-0 pa-0"
                      ref="profileImageField"
                      @change="(file) => uploadProfileImage(file)"
                      accept="image/*"
                    ></v-file-input>
                  </v-col>
                </template>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="mb-n3">
              <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">
                  Edit your address
                </div>
                <v-text-field
                  :rules="[(v) => !!v || 'Required']"
                  required
                  persistent-hint
                  outlined
                  v-model="userCopy.address"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="pb-7">
              <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">
                  Edit your birthday
                </div>
                <div v-if="!userCopy.dob" class="red--text body-2">
                  Not set
                </div>
                <div v-else class="red--text body-2">{{ userCopy.dob }}</div>
              </v-list-item-content>

              <v-list-item-icon class="align-self-center">
                <v-btn
                  class="primary align-center"
                  @click="userDetails.dob = true"
                >
                  Set
                </v-btn>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-btn
                class="primary"
                block
                :disabled="!userCopy.dob || !userCopy.address || !(userCopy.image && !userCopy.image.includes('users/default/default.jpg'))"
                @click="setProfileDetails"
                >Save</v-btn
              >
            </v-list-item>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDetails.dob" persistent max-width="500px">
      <v-card class="text-center white--text py-4 px-2" color="primary">
        <h3>Please set your birthday!</h3>
      </v-card>
      <v-date-picker 
      v-model="dummyDOB"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01" 
      full-width>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="() => {
          userDetails.dob = false;
          dummyDOB = userCopy.dob;
        }"
        text
        > Cancel </v-btn>
        <v-btn color="primary" @click="() => {
            userDetails.dob = false;
            userCopy.dob = dummyDOB;
          }"
          :disabled="!currentUser.dob"
          text
          > Ok </v-btn>
      </v-date-picker>
    </v-dialog>

    <!-- invitation sheet -->
    <!-- <intract-list-sheet
      title="Invite Users"
      :visible="showInviteSheet"
      :list="invitationOptions"
      @close="showInviteSheet = false"
    /> -->

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        userCopy.image = croppedImage;
      }"
    />

    <confirmation-dialog
      title="Are you sure you want to logout?"
      description="This will redirect you back to the login screen"
      :visible="logoutDialog"
      @successCallback="$router.push({ name: 'Logout' })"
      @failureCallback="logoutDialog = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import { Share } from "@capacitor/share";
import moment from "moment";
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import ChangePasswordSheet from "@components/auth/ChangePasswordSheet";
import { Browser } from "@capacitor/browser";

export default {
  name: "Profile",
  mixins: [Mixins.essentials],
  components: {
    ConfirmationDialog: () => import("@components/dialogs/ConfirmationDialog"),
    ChangePasswordSheet,
    ImageCropperDialog,
  },
  data() {
    return {
      dummyDOB: null,
      userCopy: null,
      moment: moment,
      logoutDialog: false,
      showInviteSheet: false,
      visible: false,
      showChangePasswordSheet: false,
      platform: null,
      userDetails: {
        dob: false,
        address: false,
        image: false,
        visible: false,
      },
      uploadedImage: null,
    };
  },
  computed: {
    ...mapGetters([
      "accessToken",
      "isLoading",
      "currentUser",
      "currentInstitution",
      "userSubjects",
    ]),
    appBarActions() {
      var actions = [
        // {
        //   title: "Invite",
        //   // onClick: ,
        //   icon: "mdi-account-plus",
        // },

        {
          title: "Change Password",
          onClick: () => (this.showChangePasswordSheet = true),
          icon: "mdi-lock-reset",
        },
        ...(this.currentUser.is_admin || this.currentUser.is_faculty
          ? [
              {
                title: "Invite Users",
                onClick: async () => {
                  if (this.platform == "web") {
                    let text = `Download the ${this.endpoints.appTitle} for our institution.\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`;
                    await this.Helper.copyText(text);
                    this.showSnackbar({
                      title: "Invite message copied to clipboard!",
                      type: "success",
                    });
                  } else {
                    Share.share({
                      title: `Check out the ${this.endpoints.appTitle} app`,
                      text: `Download the ${this.endpoints.appTitle} for our institution.\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`,
                    });
                  }
                },
                icon: "fa-sign-in-alt",
              },
            ]
          : []),
        // ...(this.currentUser.is_student && this.currentUser.dob
        //   ? [
        //       {
        //         title: "Edit Birthday",
        //         onClick: this.editBirthday,
        //         icon: "mdi-cake",
        //       },
        //     ]
        //   : []),
        ...(this.currentUser.is_student &&
        this.currentInstitution.preferences.app_student_extra_data
          ? [
              {
                title: "Edit Profile Details",
                onClick: this.editProfileDetails,
                icon: "mdi-account",
              },
            ]
          : []),
        ...this.getCustomLinks(),
        {
          title: "Logout",
          onClick: this.logout,
          icon: "mdi-account-arrow-right",
        },
      ];
      return actions;
    },
    // invitationOptions() {
    //   return [
    //     {
    //       icon: "fa-user-graduate",
    //       title: "Invite Students",
    //       subtitle: "Send an invitation message to your students",
    //       // action: () => (this.createImagePostSheet.visible = true),
    //       action: () => Share.share({
    //           title: 'See cool stuff',
    //           text: '',
    //           url: '',
    //       }),
    //     },
    //     {
    //       icon: "fa-chalkboard-teacher",
    //       title: "Invite Staff",
    //       subtitle: "Send an invitation message to your teachers & faculty",
    //       // action: () => (this.createVideoPostSheet.visible = true),
    //     },
    //     // {
    //     //   icon: "mdi-folder-multiple-image",
    //     //   title: "Folder",
    //     //   subtitle: "Create a folder to better organise your gallery",
    //     //   action: () => (this.editFolderSheet.visible = true),
    //     // },
    //   ];
    // },
    currentUserDesignation() {
      if (this.currentUser.is_admin && this.currentUser.is_faculty)
        return "Admin | Faculty";
      else if (this.currentUser.is_faculty) return "Faculty";
      else if (this.currentUser.is_student)
        return this.currentUser.room.sections;
      else return "";
    },
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading", "changeAppBarVisibility"]),
    async logout() {
      this.logoutDialog = true;
    },

    // editBirthday() {
    //   this.visible = true;
    // },

    openLink(link){
      Browser.open({ url: link })
    },

    getCustomLinks(){
      const custom_links = this.currentInstitution.preferences.custom_links
      if(custom_links){
        return Object.keys(custom_links).map(key => {
          return {
              title: key,
              onClick: () => this.openLink(custom_links[key]),
              icon: 'mdi-link'
            }
          }
        )
      }else{
        return []
      }
    },

    editProfileDetails() {
      this.userDetails.visible = true;
    },

    async setBirthday() {
      var url = this.endpoints.userProfileViewSet + this.currentUser.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { dob: this.userCopy.dob }
      );
      if (response) {
        this.visible = false;
        this.currentUser.dob = this.userCopy.dob;
      }
    },

    async setProfileDetails() {
      var url = this.endpoints.userProfileViewSet + this.currentUser.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        {
          image: this.userCopy.image,
          address: this.userCopy.address,
          dob: this.userCopy.dob,
        }
      );
      if (response) {
        this.userDetails.visible = false;
        this.currentUser.image = this.userCopy.image;
        this.currentUser.address = this.userCopy.address;
        this.currentUser.dob = this.userCopy.dob;
      }
    },

    async uploadProfileImage(file) {
      if (!file) return;
      this.uploadedImage = await this.Helper.toBase64(file);
      this.$refs.cropperDialog.initCropper(file.type);
    },

    async handleApiError(err) {
      this.moreLoading = false;
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Something went wrong",
          type: "error",
        });
      }
    },
  },
  destroyed() {
    this.changeAppBarVisibility(true);
  },
  async created() {
    this.changeAppBarVisibility(false);
    this.dummyDOB = this.currentUser.dob;
    this.userCopy = this.Helper.clone(this.currentUser);
    if (this.currentUser.is_admin || this.currentUser.is_faculty) {
      this.platform = await this.Helper.getDevicePlatform();
    }
    console.log(this.currentUser)
  },
};
</script>
<style scoped lang="sass">
@import '@assets/styles/global-variables.sass'

.top-right-overflow-menu
  position: fixed
  z-index: 10
  top: 0
  right: 0

// .background-rectangle
//   background: $primary
//   height: 150px

.profile-image-container
  display: flex
  justify-content: center
  width: 100%

.profile-image
  border: 3px solid white

.profile-details
  margin-top: 70px

.top-right-overflow-menu
  margin-top: env(safe-area-inset-top) !important
</style>